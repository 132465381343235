import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AppModalsProvider } from './context/AppModalsContext'
import { BuilderModalsProvider } from './context/BuilderModalsContext'
import { AppProvider } from './context/AppContext'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext'
import { EditModeProvider } from './context/EditModeContext'

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <AppModalsProvider>
            <SnackbarProvider autoHideDuration={3000}>
              <AuthProvider>
                <BuilderModalsProvider>
                  <EditModeProvider>
                     <App />
                   </EditModeProvider>
                </BuilderModalsProvider>
              </AuthProvider>
            </SnackbarProvider>
          </AppModalsProvider>
        </AppProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
