import classNames from 'classnames'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import styles from './NavItem.module.css'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

const NavItem = ({
  to,
  dropdown,
  className,
  startIcon,
  endIcon,
  navItemText,
  children,
  isChild,
  isOpen, 
  onClick, 
}) => {
 // const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <NavLink
        to={to}
        className={({ isActive }) =>
          classNames(
            styles.base,
            {
              [styles.child]: isChild,
              [styles.peer]: !isChild,
              [styles.activeBg]: (isActive && !isChild) || (isOpen && dropdown),
              [styles.activeText]: isActive && isChild,
            },
            className
          )
        }
        onClick={onClick}
      >
        {({ isActive }) => (
          <>
            {startIcon && startIcon}
            <span className='flex-1 text-sm'>{navItemText}</span>

            {endIcon
              ? endIcon
              : dropdown && (
                  <>
                    {isOpen ? (
                      <IoIosArrowUp className='h-5 w-5' />
                    ) : (
                      <IoIosArrowDown className='h-5 w-5' />
                    )}
                  </>
                )}
          </>
        )}
      </NavLink>
      {children && (
        <div
          className={classNames(
            'pl-8 flex-col gap-2',
            isOpen ? 'flex' : 'hidden'
          )}
        >
          {children}
        </div>
      )}
    </>
  )
}

export default NavItem


// const NavItem = ({
//   to,
//   dropdown,
//   className,
//   startIcon,
//   endIcon,
//   navItemText,
//   children,
//   isChild,
//   isOpen, // Receive the open state
//   onClick, // Receive the click handler
// }) => {
//   return (
//     <>
//       <NavLink
//         to={to}
//         className={({ isActive }) =>
//           classNames(
//             styles.base,
//             {
//               [styles.child]: isChild,
//               [styles.peer]: !isChild,
//               [styles.activeBg]: (isActive && !isChild) || (isOpen && dropdown),
//               [styles.activeText]: isActive && isChild,
//             },
//             className
//           )
//         }
//         onClick={onClick} // Use the handler here
//       >
//         {({ isActive }) => (
//           <>
//             {startIcon && startIcon}
//             <span className='flex-1 text-sm'>{navItemText}</span>

//             {endIcon
//               ? endIcon
//               : dropdown && (
//                   <>
//                     {isOpen ? (
//                       <IoIosArrowUp className='h-5 w-5' />
//                     ) : (
//                       <IoIosArrowDown className='h-5 w-5' />
//                     )}
//                   </>
//                 )}
//           </>
//         )}
//       </NavLink>
//       {children && (
//         <div
//           className={classNames(
//             'pl-8 flex-col gap-2',
//             isOpen ? 'flex' : 'hidden'
//           )}
//         >
//           {children}
//         </div>
//       )}
//     </>
//   )
// }

// export default NavItem
