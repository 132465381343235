import Modal from '../../utils/Modal'
import ModalBody from '../../utils/Modal/ModalBody'
import ModalHeader from '../../utils/Modal/ModalHeader'
import TextField from '../../inputs/TextField'
import { IoSearchOutline } from 'react-icons/io5'
import { useAppModalsContext } from '../../../context/AppModalsContext'
import propTypes from 'prop-types'
import { LuListFilter } from 'react-icons/lu'
import { useState } from 'react'
import WorldCard from '../../cards/WorldCard'
import TemplateFilterMenu from '../../related/TemplateFilterMenu'
import styles from './TemplatesModal.module.css'
import { MODALS_NAMES } from '../../../utils/constants/app.constants'

const TemplatesModal = ({ open, onClose }) => {
  const [showFilterMenu, setShowFilterMenu] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState([])

  const { handleModalToggle, setCurrentStep } = useAppModalsContext()

  const onTemplateClick = () => {
    setCurrentStep(2)
    handleModalToggle(MODALS_NAMES.TEMPLATES)
    handleModalToggle(MODALS_NAMES.CHOOSE_AVATAR)
  }

  //close this modal and open new world modal (back button of modal header)
  const onBackClick = () => {
    onClose()
    handleModalToggle(MODALS_NAMES.NEW_WORLD)
  }

  const handleToggleFilterMenu = () => setShowFilterMenu((prev) => !prev)

  return (
    <>
      <Modal open={open} onClose={onClose} size='full'>
        <ModalHeader
          onClose={onClose}
          hasBack
          title='Choose template'
          onBackClick={onBackClick}
        />
        <ModalBody>
          {open && (
            <>
              <div className={styles.header}>
                <TextField
                  placeholder='Search for worlds, templates'
                  startAdornment={
                    <IoSearchOutline className='text-secondary' />
                  }
                  containerProps={{
                    className: 'md:min-w-72',
                  }}
                />

                <div className={styles.filterContainer}>
                  <h5 className='text-base font-bold'>Filters</h5>
                  <LuListFilter
                    role='button'
                    onClick={handleToggleFilterMenu}
                  />
                  {showFilterMenu && (
                    <TemplateFilterMenu
                      className={'top-24 right-6 bottom-3'}
                      selectedFilters={selectedFilters}
                      updateSelectedFilters={setSelectedFilters}
                      onClose={handleToggleFilterMenu}
                      open={showFilterMenu}
                    />
                  )}
                </div>
              </div>

              <div className={styles.templatesContainer}>
                {Array.from({ length: 9 }).map((_, index) => (
                  <WorldCard
                    key={index}
                    world={{
                      title: 'Title lorem',
                      description:
                        'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus consequatur recusandae nisi magni? Ea provident distinctio aliquam aspernatur, cum aut dolor. Magni optio minus, voluptatem dicta nulla accusantium eos ea?',
                    }}
                    mediaCarousel
                    actionText={'Use Template'}
                    onClick={onTemplateClick}
                  />
                ))}
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

TemplatesModal.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
}

export default TemplatesModal


// import Modal from '../../utils/Modal'
// import ModalBody from '../../utils/Modal/ModalBody'
// import ModalHeader from '../../utils/Modal/ModalHeader'
// import TextField from '../../inputs/TextField'
// import { IoSearchOutline } from 'react-icons/io5'
// import { useAppModalsContext } from '../../../context/AppModalsContext'
// import propTypes from 'prop-types'
// import { LuListFilter } from 'react-icons/lu'
// import { useState } from 'react'
// import WorldCard from '../../cards/WorldCard'
// import TemplateFilterMenu from '../../related/TemplateFilterMenu'
// import styles from './TemplatesModal.module.css'
// import { MODALS_NAMES } from '../../../utils/constants/app.constants'

// const TemplatesModal = ({ open, onClose }) => (
//   <Modal open={open} onClose={onClose} size='full'>
//     <ModalHeader onClose={onClose} title='Choose template' />
//     <ModalBody>
//       {/* Simplified Body for Debugging */}
//       <div>Template Modal Content</div>
//     </ModalBody>
//   </Modal>
// );

// export default TemplatesModal