import NavItem from '../../navigation/NavItem/NavItem'
import Button from '../../inputs/Button'
import { bottomNavList, topNavList } from '../../../data/navlist'
import whiteLogo from '../../../assets/images/logo/white-logo.png'
import classNames from 'classnames'
import styles from './Sidebar.module.css'
import { IoMdClose } from 'react-icons/io'
import { FaPlus } from 'react-icons/fa6'
import { useAppModalsContext } from '../../../context/AppModalsContext'
import { useHomeContext } from '../../../context/HomeContext'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { pathTitles } from '../../../router/pathConstants'
import { MODALS_NAMES } from '../../../utils/constants/app.constants'

// const Sidebar = ({ showSidebar, setShowSidebar }) => {
//   const { handleModalToggle } = useAppModalsContext()
//   const { setHeaderTitle } = useHomeContext()

//   const location = useLocation()

//   useEffect(() => {
//     const foundTitle = pathTitles.find(
//       (path) => path.path === location.pathname
//     )

//     if (foundTitle) {
//       setHeaderTitle(foundTitle.title)
//     }
//   }, [location, setHeaderTitle])

//   return (
//     <aside
//       className={classNames(styles.base, {
//         [styles.show]: showSidebar,
//       })}
//     >
//       <div className={styles.header}>
//         <div className='flex-1 flex justify-between'>
//           <img
//             src={whiteLogo}
//             alt='White HoloFair logo'
//             className={classNames(
//               'max-w-[60%]',
//               {
//                 'flex-1': !showSidebar,
//                 'w-1/2': showSidebar,
//               },
//               'md:flex-1'
//             )}
//           />
//           {showSidebar && (
//             <button
//               onClick={() => setShowSidebar(!showSidebar)}
//               className='md:hidden'
//             >
//               <IoMdClose className='h-8 w-8 text-white' />
//             </button>
//           )}
//         </div>
//         <hr className='border-gray-300 border-opacity-10 mt-5' />
//       </div>
//       <div className={styles.content}>
//         <Button
//           fullWidth
//           className='mb-6 shrink-0 !bg-white !text-primary'
//           onClick={() => handleModalToggle(MODALS_NAMES.NEW_WORLD)}
//         >
//           <span className='text-sm font-bold'>New world</span>
//           <FaPlus className='w-4 h-4 ' />
//         </Button>
//         <nav className={styles.nav}>
//           {topNavList.map((item, index) => (
//             <NavItem
//               key={`top-nav-item-${index}`}
//               to={item.to}
//               navItemText={item.navItemText}
//               startIcon={item.startIcon}
//               endIcon={item.endIcon}
//               dropdown={item.dropdown}
//               isChild={item.isChild}
//               className={item.className}
//             >
//               {item.children &&
//                 item.children.map((child, index) => (
//                   <NavItem
//                     key={`top-nav-item-${index}`}
//                     to={child.to}
//                     navItemText={child.navItemText}
//                     startIcon={child.startIcon}
//                     endIcon={child.endIcon}
//                     dropdown={child.dropdown}
//                     isChild={child.isChild}
//                     className={child.className}
//                   />
//                 ))}
//             </NavItem>
//           ))}
//         </nav>
//         <div className='flex flex-col gap-2'>
//           {bottomNavList.map((item, index) => (
//             <NavItem
//               key={`bottom-nav-item-${index}`}
//               to={item.to}
//               navItemText={item.navItemText}
//               startIcon={item.startIcon}
//               endIcon={item.endIcon}
//               dropdown={item.dropdown}
//               isChild={item.isChild}
//               className={item.className}
//             />
//           ))}
//         </div>
//       </div>
//     </aside>
//   )
// }

// export default Sidebar


import { useState } from 'react'

const Sidebar = ({ showSidebar, setShowSidebar }) => {
  const { handleModalToggle } = useAppModalsContext()
  const { setHeaderTitle } = useHomeContext()
  const location = useLocation()
  
  const [openNavItem, setOpenNavItem] = useState(null) // Track the currently open NavItem

  useEffect(() => {
    const foundTitle = pathTitles.find(
      (path) => path.path === location.pathname
    )
    if (foundTitle) {
      setHeaderTitle(foundTitle.title)
    }
  }, [location, setHeaderTitle])

  const handleNavItemClick = (navItemText) => {
    // Toggle open state for the clicked NavItem, close others
    setOpenNavItem(prev => (prev === navItemText ? null : navItemText))
  }

  return (
    <aside className={classNames(styles.base, { [styles.show]: showSidebar })}>
      <div className={styles.header}>
        <div className='flex-1 flex justify-between'>
          <img
            src={whiteLogo}
            alt='White HoloFair logo'
            className={classNames(
              'max-w-[60%]',
              {
                'flex-1': !showSidebar,
                'w-1/2': showSidebar,
              },
              'md:flex-1'
            )}
          />
          {showSidebar && (
            <button
              onClick={() => setShowSidebar(!showSidebar)}
              className='md:hidden'
            >
              <IoMdClose className='h-8 w-8 text-white' />
            </button>
          )}
        </div>
        <hr className='border-gray-300 border-opacity-10 mt-5' />
      </div>
      <div className={styles.content}>
        <Button
          fullWidth
          className='mb-6 shrink-0 !bg-white !text-primary'
          onClick={() => handleModalToggle(MODALS_NAMES.NEW_WORLD)}
        >
          <span className='text-sm font-bold'>New world</span>
          <FaPlus className='w-4 h-4 ' />
        </Button>
        <nav className={styles.nav}>
          {topNavList.map((item, index) => (
            <NavItem
              key={`top-nav-item-${index}`}
              to={item.to}
              navItemText={item.navItemText}
              startIcon={item.startIcon}
              endIcon={item.endIcon}
              dropdown={item.dropdown}
              isChild={item.isChild}
              className={item.className}
              isOpen={openNavItem === item.navItemText} // Pass down the open state
              onClick={() => handleNavItemClick(item.navItemText)} // Handle click
            >
              {item.children &&
                item.children.map((child, index) => (
                  <NavItem
                    key={`top-nav-item-${index}`}
                    to={child.to}
                    navItemText={child.navItemText}
                    startIcon={child.startIcon}
                    endIcon={child.endIcon}
                    dropdown={child.dropdown}
                    isChild={child.isChild}
                    className={child.className}
                  />
                ))}
            </NavItem>
          ))}
        </nav>
        <div className='flex flex-col gap-2'>
          {bottomNavList.map((item, index) => (
            <NavItem
              key={`bottom-nav-item-${index}`}
              to={item.to}
              navItemText={item.navItemText}
              startIcon={item.startIcon}
              endIcon={item.endIcon}
              dropdown={item.dropdown}
              isChild={item.isChild}
              className={item.className}
            />
          ))}
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
