import React, { createContext, useState } from 'react';

// Create the Context
export const EditModeContext = createContext();

// Create a provider component that wraps around the app and provides the global state
export const EditModeProvider = ({ children }) => {
  // Manage the global edit mode state
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    // Provide the isEditMode state and the function to update it (setIsEditMode)
    <EditModeContext.Provider value={{ isEditMode, setIsEditMode }}>
      {children}
    </EditModeContext.Provider>
  );
};
