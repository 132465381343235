import classNames from 'classnames'
import PropTypes from 'prop-types'
import { cloneElement, useState } from 'react'
import { FaUser } from 'react-icons/fa'
import styles from './Avatar.module.css'

const Avatar = ({
  src,
  alt,
  variant,
  className,
  children,
  onClick,
  errorComponent,
  ...props
}) => {
  const [validImage, setValidImage] = useState(true)

  //add classes to the child if it is an icon
  const modifiedChildren = children
    ? typeof children !== 'string'
      ? cloneElement(children, {
          className: classNames(children.props.className, styles.icon),
        })
      : children
    : null

  return (
    <div
      className={classNames(
        styles.root,
        'w-10 h-10',
        {
          'bg-secondary': !src || !validImage,
          'cursor-pointer': onClick,
        },
        styles[variant],
        className
      )}
      onClick={onClick}
      {...props}
    >
      {children ? (
        modifiedChildren
      ) : src ? (
        <>
          {!validImage ? (
            <>
              {errorComponent ? (
                { errorComponent }
              ) : (
                //  <FaUser className={styles.icon} />
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="white"/>
                  <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#D1D5DB"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7491 17.5C19.7491 16.3065 20.2232 15.1619 21.0671 14.318C21.9111 13.4741 23.0556 13 24.2491 13C25.4426 13 26.5872 13.4741 27.4311 14.318C28.275 15.1619 28.7491 16.3065 28.7491 17.5C28.7491 18.6935 28.275 19.8381 27.4311 20.682C26.5872 21.5259 25.4426 22 24.2491 22C23.0556 22 21.9111 21.5259 21.0671 20.682C20.2232 19.8381 19.7491 18.6935 19.7491 17.5ZM16.0001 31.605C16.0338 29.4395 16.9178 27.3741 18.4611 25.8546C20.0044 24.3351 22.0833 23.4834 24.2491 23.4834C26.4149 23.4834 28.4938 24.3351 30.0372 25.8546C31.5805 27.3741 32.4644 29.4395 32.4981 31.605C32.5007 31.7508 32.4608 31.8942 32.3832 32.0176C32.3055 32.141 32.1936 32.2392 32.0611 32.3C29.6103 33.4237 26.9453 34.0037 24.2491 34C21.4631 34 18.8161 33.392 16.4371 32.3C16.3046 32.2392 16.1927 32.141 16.1151 32.0176C16.0375 31.8942 15.9975 31.7508 16.0001 31.605Z" fill="#1C1C1E"/>
                </svg>

              )}
            </>
          ) : (
            <img src={src} alt={alt} onError={() => setValidImage(false)} />
          )}
        </>
      ) : (
        <>
          {errorComponent ? (
            { errorComponent }
          ) : (
            <FaUser className={styles.icon} />
          )}
        </>
      )}
    </div>
  )
}

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  variant: PropTypes.oneOf(['square', 'rounded', 'circle']),
  children: PropTypes.element,
}

Avatar.defaultProps = {
  variant: 'circle',
}

export default Avatar
